import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Buttons } from "../decorators/confirm-operation";
import { duration } from "moment";

@Injectable({
  providedIn: "root"
})
export class DialogService {

  private static service: MatDialog | undefined = undefined;

  public constructor(service: MatDialog) {
    DialogService.service = service;
  }

  public static getService(): MatDialog {
    if (!DialogService.service) {
      throw new Error("DialogService not initialized");
    }
    return DialogService.service;
  }

  public static show(
    component: any,
    data: {
      error?: boolean,
      message?: string,
      header?: string,
      link?: string,
      buttons?: Buttons,
      company?: any,
      subheader?: string,
      context?: string,
      logs?: any,
      lcpDetails?: any,
      fromDate?: any,
      toDate?: any,
      duration?: number,
      buttonColors?: Buttons
    } = {},
    options: any = {
      width: "95%",
      maxWidth: "650px",
      maxHeight: "50%",
      autoFocus: 'dialog',
      disableClose: true,
      hasBackdrop: true,
      backdropClass: "dark-backdrop",
      panelClass: "dialog-panel",
      closeOnNavigation: false,
      restoreFocus: false,
      data: data,
    }
  ) {
    const dialogService = DialogService.getService();
    if (dialogService.openDialogs && dialogService.openDialogs.length > 0) return;
    return DialogService.getService().open(component, options);
  }
}
